<template>
  <div>
    <v-card class="card-shadow border" color="white" style="overflow: inherit;">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :search="search" :items-per-page="10" class="elevation-1"
        :loading="loading" :loading-text="message">
        <template v-slot:item.action="{ item }">
          <!-- <v-btn @click="handleRedirect(item.id)" class="ma-2" outlined color="indigo">
            {{ item.action }}
          </v-btn> -->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small outlined color="primary" dark v-bind="attrs" v-on="on">
                {{ item.action }} <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click='updateStaff(item)'><v-list-title>Edit</v-list-title></v-list-item>
              <v-list-item link @click='staffPass(item)'><v-list-title>Change Password</v-list-title></v-list-item>
              <v-list-item link @click='deleteStaff(item)'><v-list-title>Delete</v-list-title></v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card v-if="dialogFor === 'update'">
          <v-card-title>
            <span class="text-h5">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="staffFirstName" label="first name*" required />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="staffLastName" label="last name*" required />
                  </v-col>
                  <v-col cols="12">
                    <v-select v-model="roleValue" :items="roleItems" label="role">
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateUser(staffId)" :loading="btnLoading">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="dialogFor === 'password'">
          <v-card-title>
            <span class="text-h5">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="password" :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1" label="Password*"
                    required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="password_confirmation" :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show2 = !show2"
                    label="Conform Password*" required />
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="changePassword(staffId)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="dialogFor === 'delete'">
          <v-card-title class="text-h6">
            Are you sure to delete this user
          </v-card-title>

          <v-card-actions>
            <v-spacer />

            <v-btn color="secondary" text @click="dialog = false">
              Close
            </v-btn>

            <v-btn color="error" text @click="deleteUser(staffId)">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="primary" outlined>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
 
<script>

export default {
  data() {
    return {
      // modal
      dialog: false,
      dialogFor: '',
      snackbar: false,
      timeout: 2000,
      btnLoading: false,
      // edit Staff 
      staffFirstName: '',
      staffLastName: '',
      password: '',
      password_confirmation: '',
      show1: false,
      show2: false,
      staffId: '',
      valid: false,
      roleItems: [],
      roleValue: '',
      roleData: [],
      // data Table 
      tableData: [],
      data: '',
      message: 'Loading... Please wait',
      loading: true,
      search: '',
      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Action', value: 'action' }
      ]
    };
  },
  props: {
    currentServer: Object,
  },
  methods: {
    handleRedirect(index) {
      console.log(index);
      this.$router.push(`/skyfi-dashboard/business/${index}`);
    },
    updateStaff(item) {
      const roleid = JSON.parse(item?.roleId)
      let roleValue = '';
      if(roleid){
       roleValue = this.roleData?.find((element) => element.id == roleid)?.name;
      }
      this.dialogFor = 'update'
      this.staffId = item?.user_id
      this.staffFirstName = item?.first_name
      this.staffLastName = item?.last_name
      this.roleValue = roleValue
      this.dialog = true
    },
    staffPass(item) {
      this.dialogFor = 'password'
      this.password = ''
      this.password_confirmation = ''
      this.staffId = item?.user_id
      this.dialog = true
    },
    deleteStaff(item) {
      this.dialogFor = 'delete'
      this.staffId = item?.user_id
      this.dialog = true
    },
    async UserList() {
      try {
        const queryFor = this.$route.query.for;
        var idQuery = ''
        if (queryFor === 'user') {
          idQuery = `?user_id=${this.$route.params.id}`
        } else {
          idQuery = ''
        }
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

        const response = await fetch(`${baseurl}admin/staff_admins${idQuery}`, {
          method: 'GET',
          'Accept': 'application/json',
          headers: myHeaders,
          'Content-Type': 'application/json',
          'redirect': 'follow',
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();

        this.data = JSON.stringify(data);
        // Handle successful login here
        const TableData = data?.data?.map((item) => {
          return {
            user_id: item?.id,
            email: item?.attributes?.email,
            name: (item?.attributes?.first_name ?? '') + ' ' + (item?.attributes?.last_name ?? ''),
            first_name: item?.attributes?.first_name,
            last_name: item?.attributes?.last_name,
            roleId: item?.attributes?.role_ids,
            action: 'options',
          }
        })
        this.tableData = TableData;
        this.loading = false
        this.message = ''

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.loading = false
          this.message = 'something went wrong'
        }
      }
    },
    async deleteUser(id) {
      try {
        this.btnLoading = true;
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/staff_admins/${id}`, {
          method: 'DELETE',
          headers: myHeaders,
          'redirect': 'follow',
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        this.UserList();
        this.btnLoading = false;
        this.dialog = false;
        this.snackbarText = data?.success;
        this.snackbar = true;
      } catch (error) {
        this.btnLoading = false;
        this.dialog = false;
        this.snackbarText = error.message;
        this.snackbar = true;
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        }
      }
    },
    async updateUser(id) {
      try {
        this.btnLoading = true;
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        myHeaders.append('accept', '*/*');
        myHeaders.append('accept-language', 'en-US,en;q=0.9,en-IN;q=0.8,te;q=0.7,it;q=0.6');
        myHeaders.append('content-type', 'application/json');
        myHeaders.append('Referrer-Policy', 'strict-origin-when-cross-origin');

        let bodyData = {
          'first_name': this.staffFirstName,
          'last_name': this.staffLastName,
        }
        if (this.roleValue) {
          const role_id = this.roleData.find((item) => item?.name === this.roleValue)
          bodyData['role_ids'] = [role_id?.id];
        }
        const response = await fetch(`${baseurl}admin/staff_admins/${id}`, {
          method: 'PUT',
          headers: myHeaders,
          'redirect': 'follow',
          body: JSON.stringify({ ...bodyData }),
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        console.log(data)
        this.btnLoading = false;
        this.UserList();
        this.dialog = false;
        // return data.success
      } catch (error) {
        this.btnLoading = false;
        this.dialog = false;
        this.snackbarText = error.message;
        this.snackbar = true;
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        }
        // return error.message
      }
    },
    async changePassword(id) {
      try {
        this.btnLoading = true;
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}api/v2/staffs/${id}/change_staff_password`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            'password': this.password,
            'password_confirmation': this.password_confirmation
          }),
          'redirect': 'follow',
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else if (response.status == '422') {
            throw new Error('Password and confirmation do not match or Password does not meet strength requirements. Use capital letters, special characters, and numbers');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        this.btnLoading = false;
        this.UserList();
        this.dialog = false;
        this.snackbarText = data?.success;
        this.snackbar = true;
        // return data.success
      } catch (error) {
        this.btnLoading = false;
        this.dialog = false;
        this.snackbarText = error.message;
        this.snackbar = true;
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        }
        // return error.message
      }
    },
    async fetchRoles() {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/roles`, {
          method: 'GET',
          'Accept': 'application/json',
          headers: myHeaders,
          'Content-Type': 'application/json',
          'redirect': 'follow',
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        this.data = JSON.stringify(data);
        // Handle successful login here
        this.roleData = data
        const roleArray = data?.map((item) => item?.name)
        this.roleItems = roleArray
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          alert('something went wrong')
        }
      }
    }
  },
  mounted() {
    this.UserList();
    this.fetchRoles();
  },
};
</script>
    